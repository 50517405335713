<template lang="pug">
b-modal(v-model="show" size="lg" centered hide-header hide-footer no-close-on-backdrop)
  //- form.form-group(@submit.prevent="test" enctype="application/x-www-form-urlencoded")
  h2.my-5.main-color 變更密碼
  h5 歡迎加入wuwow Jr大家庭，請儘速變更密碼
  .form-group.mx-auto.text-left.mt-5
    p.mb-1.mt-3 新密碼
    input.form-control(v-model="password" type="password")
    P.mb-1.mt-3 再次輸入新密碼
    input.form-control(v-model="confirmPassword" type="password")
    //- button.btn.btn-primary(:disabled="isDisable" @click="sendChangePasswordRequest") 送出修改
    //- button.btn.btn-primary 送出修改
    .my-5.d-flex.justify-content-center
      button.w-100.btn.btn-outline-primary.mx-2(@click="close") 下次再說
      button.w-100.btn.btn-primary.mx-2(:disabled="isDisable" @click="sendChangePasswordRequest") 完成
</template>

<script>
import { v4 } from 'uuid';
import modalMixin from '@/mixins/modal';
import storage from '@/utils/localStorage';

export const uuid = v4();

// eslint-disable-next-line no-unused-vars
import axios from 'axios';
// eslint-disable-next-line no-unused-vars
import qs from 'qs';

export default {
  mixins: [
    modalMixin,
  ],
  data: () => ({
    changePasswordToken: '',
    password: '',
    confirmPassword: '',
  }),

  computed: {
    isDisable() {
      const isEmptyString = !this.password || !this.confirmPassword;
      const isNotSame = this.password !== this.confirmPassword;

      return isEmptyString || isNotSame;
    },
  },

  created() {
    this.initModal(uuid, {
      status: true,
      payload: null,
    });
    this.changePasswordToken = this.$route.query.token;
  },

  methods: {
    async sendChangePasswordRequest() {
      try {
        // 只有在登入狀況，才在標頭攜帶登入token
        const headerData = {
          'content-type': 'application/x-www-form-urlencoded',
          // 這是登入token
          Authorization: storage.token ? `Bearer ${storage.token}` : null,
        };
        // 未登入時，標頭不攜帶登入token
        if (!storage.token) {
          delete headerData.Authorization;
        }

        // 判斷是否為登入中的家長，切換不同變更密碼API
        const accountRole = localStorage.getItem('account_role');
        const wuwowToken = localStorage.getItem('wuwow_token');

        if (accountRole === 'parent' && wuwowToken) {
          const requestData = qs.stringify({
            platform: 'wuwow_junior',
            // old_password: '',
            password: this.password,
            password_confirmation: this.confirmPassword,
          });
          await axios.patch(
            `${process.env.VUE_APP_BASE_API}/v3/lst-oauth/authentication/reset-password`,
            requestData,
            {
              headers: headerData,
            }
          );
        } else {
          const data = qs.stringify({
            platform: 'wuwow_junior',
            identity: 'student',
            // 此為修改密碼專用 token，只放在 payload 裡
            token: `${this.changePasswordToken}`,
            // JR 回傳空值即可
            // email: '',
            password: this.password,
            password_confirmation: this.confirmPassword,
          });
          await axios.patch(
            `${process.env.VUE_APP_BASE_API}/v3/lst-oauth/sign-up/reset-forget-password`,
            data,
            {
              headers: headerData,
            }
          );
        }

        await this.$swal({
          title: '<strong>密碼變更成功</strong><br><br><img src="https://cdn2.wuwow.tw/wuwow-junior/correct.png"><br>',
          html: '<br>再麻煩重新登入一次...</br>3秒後將為您轉跳首頁',
          // type: 'success',
          showConfirmButton: false,
          allowOutsideClick: false,
          timer: 3000,
        });
      } catch (error) {
        // 這個奇書沒有給畫面 我先放一個 by Jill
        await this.$swal({
          title: '<strong>密碼變更失敗</strong><br>',
          html: '<br>再麻煩重新變更一次...</br>3秒後將為您轉跳首頁',
          type: 'error',
          showConfirmButton: false,
          allowOutsideClick: false,
          timer: 3000,
        });
      } finally {
        this.$router.push('/');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/parent/modal.scss';

/deep/.btn {
  &:hover {
    color: #FFFFFF;
    // background-color: aqua;
    background-color: #0069d9;
  }
}
</style>
<style lang="scss">
.swal2-container {
  background-image: url(https://cdn2.wuwow.tw/wuwow-junior/vip-background-web.svg);
  background-size: cover;

  .swal2-modal {
    // background: rgba(255, 255, 255, 0.75) !important;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 0px 14px rgba(208, 208, 208, 0.25);
    border-radius: 15px;
  }

  .swal2-success-circular-line-right {
    background: #ffffff00;
  }
}
</style>
